import validate from "/codebuild/output/src530013794/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_ioredis@_6693a622053a1487027a1406816d8f43/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/codebuild/output/src530013794/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/middleware/redirectTrailingSlash.global.ts";
import redirect_45global from "/codebuild/output/src530013794/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/middleware/redirect.global.ts";
import manifest_45route_45rule from "/codebuild/output/src530013794/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@8.57.1_ioredis@_6693a622053a1487027a1406816d8f43/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}